import * as linkedIn from '../img/LinkedIn Circled.svg';
import * as T2LLogo from '../img/T2LLogo.svg';
const template = document.createElement('template');

const rootDir = 'file:///D:/';
template.innerHTML = `
<footer class="footer">
<nav>
  <div class="footer-logo-container">
    <a
      class="logo"
      href="/"
      aria-label="Tech2Live Home"
    >
      <img
        class="footer-logo"
        src="${T2LLogo}"
        alt="Tech2Live"
      />
    </a>
    <div class="icon-links-large">
      <a
        aria-label="???"
        href="https://www.linkedin.com/company/tech2live"
        target="_blank"
      >
        <img
        class="linkedin-img"
        src="${linkedIn}"
          alt="linkedin"
        />
      </a>
    </div>
  </div>
  <div class="list-container">
    <ul
      role="list"
      class="footer-row"
    >
      <li><a href="/pages/about/about.html">About Us</a></li>
      <li><a href="/pages/product/product.html">Product</a></li>
      <li><a href="/pages/faq/faq.html">FAQs</a></li>
      <!-- <li><a href="./index.html#partners">Partners</a></li> -->
    </ul>

    <ul
      role="list"
      class="footer-row"
    >
      <li class="footer-contact">Contact Us</li>
      <li ><a class="footer-contact-link" href="mailto:hello@tech2live.org">hello@tech2live.org</a></li>
      <!-- <li><a href="#">Legal</a></li> -->
    </ul>
  </div>
  <div class="icon-links-mobile">
    <a
      aria-label="???"
      href="https://www.linkedin.com/company/tech2live"
      target="_blank"
    >
      <img
        class="icon-link-mobile"
        src="${linkedIn}"
        alt="linkedin"
      />
    </a>
  </div>
</nav>
</footer>
`;
document.body.appendChild(template.content);
